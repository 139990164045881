import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Section } from 'components';
import { Container, Grid, Typography } from '@material-ui/core';
import { CfiInfo } from './components';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 700,
    marginBottom: '15px',
  },
  form: {
    marginTop: '55px',
  },
  formGrid: {
    padding: "45px",
  },
  map: {
    padding: "45px",
    width: "100%",
  }
}));

const Support = () => {
  const classes = useStyles();
  
  return (
    <>
      <Section light>
        <Container>
          <Typography className={classes.title} variant="h1">Support</Typography>
          <Typography variant="subtitle1">Nous sommes à votre disposition pour vous conseiller et vous aider à mener à bien votre projet.</Typography>
          <Typography variant="subtitle1">Avec nos partenaires réputés, nous réalisons vos rêves!</Typography>
          <Grid
            className={classes.form}
            container
            direction="row"
            justify="space-around"
            alignItems="flex-start"
          >
            <Grid className={classes.formGrid} item md={9}>
              <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
              <iframe title="contact-form" id="airtable-contact-form" class="airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/shrnptBlqF4WHBSjX?backgroundColor=orange" frameBorder="0" onmousewheel="" width="100%" height="895" style={{ "background": "white; border: 1px solid #fff;" }} />
            </Grid>
            <Grid item md={3}>
              <CfiInfo />
            </Grid>
          </Grid>
          <Grid
            className={classes.form}
            container
            direction="row"
            justify="center"
            alignItems="center">
            <Grid item md={9}>
              <img className={classes.map} alt="map" src="images/map.png" />
            </Grid>
            <Grid item md={3}>
              &nbsp;
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
};

export default Support;
