import palette from './palette';

const common = {
  mainButton: {
    backgroundColor: '#faad3d',
    '&:hover': {
      backgroundColor: '#efa335'
    },
    color: palette.white,
    fontWeight: 600,
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  mainRoundedButton: {
    marginTop: '25px',
    padding: '15px 25px 15px 25px',
    fontFamily: 'Roboto',
    color: palette.white,
    backgroundColor: palette.orange,
    borderRadius: 25,
    fontWeight: 600,
    fontSize: '12px',
    '&:hover': {
      backgroundColor: palette.orange
    }
  },
  darkRoundedButton: {
    marginTop: '25px',
    padding: '15px 25px 15px 25px',
    fontFamily: 'Roboto',
    color: palette.white,
    backgroundColor: palette.black,
    borderRadius: 25,
    fontWeight: 600,
    fontSize: '12px',
    '&:hover': {
      backgroundColor: palette.orange
    }
  },
  bigCenteredTitle: {
    fontWeight: 900
  },
  h3Title: {
    fontWeight: 600,
    paddingBottom: '35px'
  },
  priceLabel: {
    color: '#8FD021',
    fontWeight: 600
  },
  bodyOfText: {
    fontWeight: 500
  },
  link: {
    marginTop: '12px',
    marginBottom: '12px',
    fontWeight: 500,
    color: palette.orange,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  cursorHand: {
    cursor: 'pointer'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  fullWidth: {
    width: '100%'
  },
  bold: {
    fontWeight: 600
  },
  invisible: {
    opacity: '0 !important'
  },
  opacity0: {
    opacity: '0 !important'
  },
  mt25: {
    marginTop: '25px'
  }
};

export default common;
