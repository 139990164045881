import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import { Box, Link } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  ...theme.common,
  mb35: theme.margins.mb35,
  partnersLogo: {
    maxWidth: "95px",
  },
}));

const logos = [
  { label: "01.png", link: "https://fernox.fr/" },
  { label: "02.jpg", link: "https://www.ithodaalderop.be/" },
  { label: "03.jpg", link: "https://www.poujoulat.be/fr/" },
  { label: "04.png", link: "https://www.walraven.com/be-fr/" },
  { label: "05.jpg", link: "https://www.vaillant.fr/accueil/" },
  { label: "06.jpg", link: "https://www.stelrad.eu/stelrad_fra/" },
  { label: "07.jpg", link: "https://nicoll.be/" },
  { label: "08.png", link: "https://www.ubbink.com/be/" },
  { label: "09.png", link: "http://www.ape-raccorderie.com/fr/Raccords.html" },
  { label: "10.jpg", link: "https://benelux.giacomini.com/fr" },
];

const OurPartners = () => {
  const classes = useStyles();

  return (
    <Box sx={{ p: 12 }}>
      <Typography
        className={clsx(classes.bigCenteredTitle, classes.mb35)}
        variant="h2"
        align="center"
      >
        Nos partenaires
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={6}
      >
        {logos.map((partenaire) => (
          <Grid item xs={4} md={2} key={partenaire.label}>
            <Link href={partenaire.link} target="_blank">
              <img
                className={classes.partnersLogo}
                alt={partenaire.label}
                src={`images/partenaires/${partenaire.label}`}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OurPartners;
