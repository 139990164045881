import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  ...theme.common,
  fillWidth: {
    maxWidth: "100%",
  }
}));

const VideoPresentation = () => {
  const classes = useStyles();
  
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={8}
    >
      <Grid item xs={12} md={4}>
        <img className={classes.fillWidth} alt="video-placeholder" src="images/about-insides.jpg" />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography className={classes.h3Title} variant="h4">Vidéo de présentation.</Typography>
        <Typography variant="body1">Une image vaut milles mots ! Découvrez sans plus attendre la vidéo de présentation de notre nouveau point de vente,</Typography>
      </Grid>
    </Grid>
  );
};

export default VideoPresentation;