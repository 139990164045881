import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Container, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { Section } from "components";
import ExtraInfoInTab from "./components";
import { AddToCartButton } from "../../components";
import getCompleteUrlForImage from "helpers/imageUrlHelper";
import { useParams, NavLink } from "react-router-dom";
import { getProductsByID, getProductStock } from "cfiAPI";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const useStyles = makeStyles((theme) => ({
  mb35: theme.margins.mb35,
  mx10: theme.margins.mx10,
  ...theme.common,
  productTitle: {
    fontWeight: 600,
  },
  gridItemImage: {
    textAlign: "center",
  },
  img: {
    maxWidth: "80%",
    maxHeight: "250px",
  },
  fillWidth: {
    maxWidth: "100%",
  },
  counter: {
    marginTop: "25px",
    marginBottom: "25px",
    padding: "25px",
  },
  ...theme.common,
  productLinks: {
    color: "black",
    marginBottom: 0,
    marginTop: 0,
    fontSize: "12px",
  },
  linkReferences: {
    marginTop: "15px",
  },
  divider: {
    marginTop: "45px",
    marginBottom: "15px",
  },
}));

const ProductView = () => {
  const classes = useStyles();
  const [product, setProduct] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const { id } = useParams();

  React.useEffect(() => {
    async function fetchData() {
      const { data } = await getProductsByID(id);
      setProduct(data[0]);
      const resp = await getProductStock(id, "123456789");
      console.log("stock resp", resp);
      setLoading(false);
    }
    fetchData();
  }, [id]);

  if (loading || !product)
    return (
      <Section light>
        <Container>Loading</Container>
      </Section>
    );

  return (
    <Section light>
      <Container>
        <Breadcrumbs
          sx={{ mb: 2 }}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <NavLink
            underline="hover"
            key="3"
            to={`/products-category-select/${product.MainTypeID}`}
          >
            {product.MainTypeName}
          </NavLink>
          <NavLink
            underline="hover"
            key="3"
            to={`/products-category-select/${product.MainTypeID}/${product.MainSubTypeID}`}
          >
            {product.MainSubTypeName}
          </NavLink>
          <NavLink
            underline="hover"
            key="3"
            to={`/products-category-select/${product.MainTypeID}/${product.MainSubTypeID}/${product.TypeID}`}
          >
            {product.TypeName}
          </NavLink>
          <NavLink
            underline="hover"
            key="3"
            to={`/products-category-select/${product.MainTypeID}/${product.MainSubTypeID}/${product.TypeID}/${product.SubTypeID}`}
          >
            {product.SubTypeName}
          </NavLink>
        </Breadcrumbs>
        <Typography className={classes.mb35} variant="h3">
          {product.Name}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6} className={classes.gridItemImage}>
            <img
              className={classes.img}
              alt="main-product"
              src={getCompleteUrlForImage(product.PicName)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              className={clsx(classes.priceLabel, classes.mx10)}
              variant="h5"
            >
              Prix brut: {product.UnitPrice} €
            </Typography>
            <Paper className={classes.counter}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <AddToCartButton product={product} />
              </Grid>
            </Paper>
            <Typography variant="subtitle2">
              Disponibilité:{" "}
              <span className={classes.priceLabel}>
                En stock ({product["StockQuantity"]})
              </span>
            </Typography>
            {/* <Box className={classes.linkReferences}>
              <Typography
                variant="subtitle1"
                className={clsx(classes.link, classes.productLinks)}
              >
                Référence {product.Reference}
              </Typography>
              <Typography
                variant="subtitle1"
                className={clsx(classes.link, classes.productLinks)}
              >
                Code EAN {product["EAN"]}
              </Typography>
              <Typography
                variant="subtitle1"
                className={clsx(classes.link, classes.productLinks)}
              >
                Marque: {product["Brand"]}
              </Typography>
            </Box> */}
          </Grid>
        </Grid>
      </Container>
      <Divider className={classes.divider} variant="fullWidth" />
      <ExtraInfoInTab product={product} />
    </Section>
  );
};

export default ProductView;
