import React, { useState } from 'react';
import { Section } from 'components';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grow,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import NewUserForm from './components/NewUserForm';
import NewWebUserForm from './components/NewWebUserForm';
import WebUserForm from './components/WebUserForm';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  loginForm: {
    marginBottom: theme.spacing(12)
  },
  submitBtn: {
    marginTop: theme.spacing(4)
  },
  ...theme.common,
  ...theme.margins
}));

const Register = () => {
  const classes = useStyles();
  const [newUserType, setNewUserType] = useState(null);

  return (
    <Section light className={classes.root}>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        hidden={newUserType}
      >
        <Grid item xs={12} md={4}>
          <Card
            elevation={0}
            variant="outlined"
            onClick={() => setNewUserType('NEW')}
          >
            <CardContent center>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0.5}
              >
                <GroupAddIcon fontSize="large" />
                <Typography
                  variant="h5"
                  fullWidth
                  alignContent="center"
                  alignItems="center"
                  align="center"
                  alignSelf="center"
                  textAlign="center"
                >
                  Nouveau client
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            elevation={0}
            variant="outlined"
            onClick={() => setNewUserType('NEWWEBUSER')}
          >
            <CardContent center>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0.5}
              >
                <GroupAddIcon fontSize="large" />
                <Typography
                  variant="h5"
                  fullWidth
                  alignContent="center"
                  alignItems="center"
                  align="center"
                  alignSelf="center"
                  textAlign="center"
                >
                  Déjà client de C-Fi
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {newUserType === 'NEW' && (
        <Grow in={true} timeout={500}>
          <NewUserForm
            goBack={() => setNewUserType(null)}
            goToOtherForm={() => setNewUserType('NEWWEBUSER')}
          />
        </Grow>
      )}
      {newUserType === 'NEWWEBUSER' && (
        <Grow in={true} timeout={500}>
          <NewWebUserForm
            goBack={() => setNewUserType(null)}
            goToOtherForm={(userType) => setNewUserType(userType)}
          />
        </Grow>
      )}
      {newUserType === 'NEWWEBUSER-FORM' && (
        <Grow in={true} timeout={500}>
          <WebUserForm
            goBack={() => setNewUserType('NEWWEBUSER')}
            goToOtherForm={() => setNewUserType('NEW')}
          />
        </Grow>
      )}
    </Section>
  );
};

export default Register;
