import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { CfiFamily, Section } from 'components';
import { HomeCardSection, HomeCarousel, NewProducts, OurPartners } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    paddingBottom: 0,
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Section light>
        <HomeCarousel />
      </Section>
      <Section >
        <CfiFamily />
      </Section>
      <Section light>
        <NewProducts />
      </Section>
      <Section>
        <HomeCardSection />
      </Section>
      <Section light>
        <OurPartners />
      </Section>
    </div>
  );
};

export default Home;