const axios = require("axios").default;
const baseUrl = "http://c-fi.ZAPTO.ORG:15021/ServiceMM.svc/";

function getConfig() {
  return {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
}

export async function getUserLogin(email) {
  const params = new URLSearchParams();
  params.append("Email ", email);

  const config = getConfig();

  return axios.post(`${baseUrl}GetUserLogin`, params, config);
}

export async function getUserSession(username, password) {
  const params = new URLSearchParams();
  params.append("Username", username);
  params.append("Password", password);
  params.append("Machine", "Machine");

  const config = getConfig();

  return axios.post(`${baseUrl}getUserSession`, params, config);
}

export async function getUserInfoByWebUser(webUserId, sessionid) {
  const params = new URLSearchParams();
  params.append("WebUserID", webUserId);

  const t = { headers: {} };
  //t.headers['Authentication'] = sessionid;
  t.headers["Content-Type"] = "application/x-www-form-urlencoded";
  const config = t;

  return axios.post(`${baseUrl}getUserInfoByWebUser`, params, config);
}

export async function getCustomerByVat(vatNbre) {
  let url = baseUrl + "getCustomerByVat/" + vatNbre;
  return await (
    await axios.get(url)
  ).data;
}

export async function insertNewUser({
  email,
  password,
  name,
  firstName,
  phone,
  address,
  numBox,
  city,
  zip,
  taxNumber,
}) {
  const params = new URLSearchParams();
  params.append("Email", email);
  params.append("Password", password);
  params.append("Name", name);
  params.append("FirstName", firstName);
  params.append("Phone", phone);
  params.append("Address", address);
  params.append("NumBox", numBox);
  params.append("City", city);
  params.append("Zip", zip);
  params.append("TaxNumber", taxNumber);
  params.append("UseDeliveryDetails", 0);
  params.append("DeliveryName", "");
  params.append("DeliveryFirstName", "");
  params.append("DeliveryAddress", "");
  params.append("DeliveryNumBox", "");
  params.append("DeliveryCity", "");
  params.append("DeliveryZip", "");
  params.append("Comment", "");

  const config = getConfig();

  return axios.post(`${baseUrl}insertNewUser`, params, config);
}

export async function insertWebUser({
  name,
  phone,
  email,
  company,
  customerID,
}) {
  const params = new URLSearchParams();
  params.append("Name", name);
  params.append("Mail", email);
  params.append("Phone", phone);
  params.append("Company", company);
  params.append("MachineID", "132456");
  params.append("CustomerID", customerID);

  const config = getConfig();

  return axios.post(`${baseUrl}/insertWebUser`, params, config);
}

export async function getProductMainTypes() {
  let url = baseUrl + "GetProductMainTypes";
  return axios.get(url);
}

export async function getCategoryLevel2(mainType) {
  let url = baseUrl + "getProductMainSubTypesByMainType/" + mainType;
  return axios.get(url);
}

export async function getCategoryLevel3(mainSubType) {
  let url = baseUrl + "getProductTypesByMainSubType/" + mainSubType;
  return axios.get(url);
}

export async function getCategoryLevel4(productType) {
  let url = baseUrl + "getProductSubTypesByProductType/" + productType;
  return axios.get(url);
}

export async function getProductList(subType) {
  let url =
    baseUrl +
    "getProductsBySubType/" +
    subType +
    "/" +
    (localStorage.getItem("CFI-CustomerId") || 0);
  return axios.get(url);
}

export async function getProductsByID(productId) {
  let url = `${baseUrl}getProductByID/${productId}`;
  return axios.get(url);
}

export async function getSnotaBasketByCustomerId(customerId) {
  if (!customerId) {
    return;
  }
  let url = `${baseUrl}getSnotaBasketByCustomerID/${customerId}`;
  return axios.get(url);
}

export async function getProductStock(productId, qt) {
  let url = `${baseUrl}getProductStockByCriteria/${productId}/${qt}`;
  return axios.get(url);
}

export async function getProductsbyMainType(mainType) {
  let url = `${baseUrl}getProductsbyMainType/${mainType}/${
    localStorage.getItem("CFI-CustomerId") || 0
  }`;
  return axios.get(url);
}

export async function getProductsByType(type) {
  let url = `${baseUrl}getProductsByType/${type}/${
    localStorage.getItem("CFI-CustomerId") || 0
  }`;
  return axios.get(url);
}

export async function getProductsByName(name) {
  let url = `${baseUrl}getProductsByName/${name}`;
  return axios.get(url);
}

export async function insertSnotaDetail(productId, qt, finalPrice, discount) {
  const params = new URLSearchParams();
  params.append("CustomerID", localStorage.getItem("CFI-CustomerId"));
  params.append("ID", productId);
  params.append("PieceID", 0);
  params.append("Quantity", qt);
  params.append("PriceType", 0);
  params.append("FinalPrice", finalPrice);
  params.append("FinalDiscount", discount);
  params.append("WebUserFid", localStorage.getItem("CFI-WebId"));
  params.append("WebUserAddress", "192.168.0.1");
  params.append("FinalDiscountStr", "100%");

  const config = getConfig();

  return axios.post(`${baseUrl}insertSnotaDetail`, params, config);
}

export async function deleteSnotaDetail(detailId) {
  const params = new URLSearchParams();
  params.append("DetailID", detailId);

  const config = getConfig();

  return axios.post(`${baseUrl}deleteSnotaDetail`, params, config);
}

export async function updateSnotaDetail(detailId, qt) {
  const params = new URLSearchParams();
  params.append("DetailID", detailId);
  params.append("PieceID", "0");
  params.append("Quantity", qt.toString());
  params.append("PriceType", "0");
  params.append("FinalPrice", "1");
  params.append("FinalDiscount", "");
  params.append("FinalDiscountStr", "Tout à 5€ maggle");

  const config = getConfig();

  return axios.post(`${baseUrl}updateSnotaDetail`, params, config);
}

export async function confirmSnotaBasket(reference) {
  const params = new URLSearchParams();
  params.append("CustomerID", localStorage.getItem("CFI-CustomerId"));
  params.append("Reference", reference);

  const config = getConfig();

  return axios.post(`${baseUrl}confirmSnotaBasket`, params, config);
}
