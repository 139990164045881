import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import getCompleteUrlForImage from 'helpers/imageUrlHelper';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    cursor: 'pointer',
    height: '200px',
    marginBottom: '25px'
  },
  productTypeImage: {
    maxWidth: '100%',
    maxHeight: '100%'
  }
}));

const ProductTypeGridElement = ({ types, onClick }) => {
  const classes = useStyles();

  return types.map((type) =>
    type.ID === '6' ? null : (
      <Grid
        key={type.ID}
        xs={6}
        sm={4}
        md={3}
        onClick={() => onClick(type.ID)}
        item
        className={classes.gridItem}
      >
        <img
          className={classes.productTypeImage}
          alt={type.Name}
          src={getCompleteUrlForImage(type.PicName)}
        />
        <Typography variant="subtitle1">{type.Name}</Typography>
        {/* <Typography variant="subtitle2">PORCELAINE</Typography> */}
      </Grid>
    )
  );
};

export default ProductTypeGridElement;
