import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  ...theme.common,
  mt25: theme.margins.mt25,
  fillWidth: {
    maxWidth: "100%",
  }
}));

const Founders = () => {
  const classes = useStyles();
  
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs={12} md={3}>
        <Typography className={classes.h3Title} variant="h4">Une équipe d'expert, à votre écoute.</Typography>
        <Typography variant="body1">Notre équipe d'experts se fera un plaisir de répondre à vos appels, vos mails et de vous conseiller à notre point de vente</Typography>
        <Button className={clsx(classes.mainButton, classes.mt25)}>NOUS CONTACTER</Button>
      </Grid>
      <Grid item xs={12} md={2}>
        <img alt="founder" src="images/about-founders.jpeg" className={classes.fillWidth} />
      </Grid>
      <Grid item xs={12} md={5}>
        <img alt="founder" src="images/about-insides.jpg" className={classes.fillWidth} />
      </Grid>
    </Grid>
  );
};

export default Founders;