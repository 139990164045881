import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Alert, Typography } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import CfiTextField from '../CfiTextField';
import { SnackbarContext } from 'contexts/SnackbarContext';
import { insertNewUser, getUserLogin } from 'cfiAPI';

const useStyles = makeStyles((theme) => ({
  root: {},
  loginForm: {
    marginBottom: theme.spacing(12)
  },
  submitBtn: {
    marginTop: theme.spacing(4)
  },
  ...theme.common,
  ...theme.margins
}));

const initialValues = {
  email: '',
  password: '',
  confirmationPassword: '',
  name: '',
  firstName: '',
  phone: '',
  address: '',
  numBox: '',
  city: '',
  zip: '',
  taxNumber: ''
};

const fields = [
  { title: 'Information personnelle' },
  { name: 'name', label: 'Nom', sizes: { xs: 12, sm: 6 } },
  { name: 'firstName', label: 'Prénom', sizes: { xs: 12, sm: 6 } },
  {
    name: 'phone',
    label: 'Numéro de téléphone',
    sizes: { xs: 12, sm: 6 },
    type: 'number'
  },
  { name: 'email', label: 'Adresse email', sizes: { xs: 12, sm: 12 } },
  {
    name: 'password',
    label: 'Mot de passe',
    type: 'password',
    sizes: { xs: 12, sm: 12 }
  },
  {
    name: 'confirmationPassword',
    label: 'Confirmer mot de passe',
    type: 'password',
    sizes: { xs: 12, sm: 12 }
  },
  { title: 'Information supplémentaire' },
  { name: 'address', label: 'Adresse', sizes: { xs: 12, sm: 12 } },
  { name: 'numBox', label: 'Numéro et boîte', sizes: { xs: 12, sm: 4 } },
  { name: 'city', label: 'Ville', sizes: { xs: 12, sm: 4 } },
  { name: 'zip', label: 'Code postal', sizes: { xs: 12, sm: 4 } },
  { name: 'taxNumber', label: 'TVA', sizes: { xs: 12, sm: 12 } }
];

const validationSchema = yup.object({
  email: yup
    .string('Entrez votre adresse mail.')
    .email('Adresse non valide')
    .required('Ce champ est obligatoire'),
  password: yup
    .string('Entrez un mot de passe')
    .required('Ce champ est obligatoire'),
  confirmationPassword: yup
    .string('confirmez le mot de passe')
    .required('Ce champ est obligatoire')
    .test(
      'passwords-match',
      'Mot de passe ne correspondent pas',
      function (value) {
        return this.parent.password === value;
      }
    ),
  name: yup.string('Entrez votre nom').required('Ce champ est obligatoire'),
  firstName: yup
    .string('Entrez votre prénom')
    .required('Ce champ est obligatoire'),
  phone: yup
    .number('Entrez votre numéro de téléphone')
    .required('Ce champ est obligatoire')
    .positive('Entrez un numéro de téléphone valide')
    .integer('Entrez un numéro de téléphone valide'),
  useDeliveryDetails: yup.bool(),
  deliveryName: yup.string().when(['useDeliveryDetails'], {
    is: (useDeliveryDetails) => {
      return useDeliveryDetails;
    },
    then: yup.string().required('Entrez un nom')
  })
});

const NewUserForm = forwardRef(({ goBack }, ref) => {
  const classes = useStyles();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);

  const { errorMessage, warningMessage, successMessage } =
    React.useContext(SnackbarContext);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      getUserLogin(values.email).then((doesExist) => {
        if (doesExist.data === -1) {
          warningMessage(
            'Cette adresse email est déjà utilisé dans le système.'
          );
          return;
        }

        insertNewUser(values)
          .catch((err) =>
            errorMessage("Une erreur s'est produite. Reessayez plus tard")
          )
          .then((resp) => {
            console.log('resp', resp);
            // Returns -1 if it did not work
            if (resp.data === -1) {
              // errorMessage("Une erreur s'est produite");
              // return;
            }

            setIsFormSubmitted(true);
            successMessage('Formulaire soumit !');
          });
      });
    }
  });

  return (
    <form
      className={classes.loginForm}
      onSubmit={formik.handleSubmit}
      ref={ref}
    >
      <Grid container justify="center">
        {isFormSubmitted === false && (
          <Grid item xs={12} md={5}>
            <Typography onClick={goBack} variant="caption">
              Retour
            </Typography>
            <Grid container justify="flex-start" spacing={1}>
              {fields.map((field) => {
                if (field.visible) {
                  if (field.visible(formik.values) === false) {
                    return null;
                  }
                }
                if (field.empty) {
                  return <Grid item {...field.sizes} />;
                }
                if (field.title) {
                  return (
                    <Grid item xs={12} className={classes.mt35}>
                      <Typography variant="h4" className={classes.h3Title}>
                        {field.title}
                      </Typography>
                    </Grid>
                  );
                }
                return (
                  <Grid item {...field.sizes}>
                    <CfiTextField
                      name={field.name}
                      label={field.label}
                      type={field.type}
                      value={formik.values[field.name]}
                      handleChange={formik.handleChange}
                      touched={formik.touched[field.name]}
                      errors={formik.errors[field.name]}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Button
              className={classes.submitBtn}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
            >
              Submit
            </Button>
          </Grid>
        )}
        {isFormSubmitted && (
          <Grid item xs={12} md={5}>
            <Alert severity="success" sx={{ mb: 2 }}>
              Votre demande de compte à bien été reçue. Vous recevrez un mail
              dans les 24h pour vos accès.
            </Alert>
          </Grid>
        )}
      </Grid>
    </form>
  );
});

export default NewUserForm;
