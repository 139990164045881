import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  carousel: {
    width: "100%",
    height: "400px",
    objectFit: "cover",
  },
  smImg: {
    height: "300px",
  },
  productTitle: {
    fontWeight: 600,
    height: "60px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  px25: theme.paddings.px25,
  mb35: theme.margins.mb35,
  ...theme.common,
}));
const NewProducts = () => {
  const classes = useStyles();

  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    setProducts([
      {
        ID: 1,
        Name: "Meubles VINCI 800 + vasque Veneto 800 1 tiroir métallique CHÊNE AFRICAIN",
        PicName: "images/carousel-img-1.jpg",
        Price: "1200",
      },
      {
        ID: 2,
        Name: "Ensemble complet NOJA BLANC",
        PicName: "images/carousel-img-1.jpg",
        Price: "1200",
      },
      {
        ID: 3,
        Name: "Meuble MONTERREY 2 tiroirs métallique BLANC BRILLANT",
        PicName: "images/carousel-img-1.jpg",
        Price: "1200",
      },
      {
        ID: 4,
        Name: "Ensemble complet NOJA GRIS",
        PicName: "images/carousel-img-1.jpg",
        Price: "1200",
      },
    ]);
  }, []);

  return (
    <React.Fragment>
      <Typography
        className={clsx(classes.bigCenteredTitle, classes.mb35)}
        variant="h2"
        align="center"
      >
        Nouveautés
      </Typography>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ m: 2 }}
      >
        <Typography variant="body2">Aucune Nouveautés...</Typography>
      </Stack>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        {/* {products.map((product) => (
          <Grid key={product.ID} item xs={12} md={3} className={classes.p25}>
            <Typography className={clsx(classes.productTitle)} variant="h5">
              {product.Name}
            </Typography>
            <img
              alt="carousel-element"
              className={clsx(classes.carousel, classes.smImg, classes.px25)}
              src={product.PicName}
            />
            <Typography className={classes.priceLabel}>
              {product.UnitPrice}€
            </Typography>
          </Grid>
        ))} */}
      </Grid>
    </React.Fragment>
  );
};

export default NewProducts;
