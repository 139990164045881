
const paddings = {
  pt0: {
    paddingTop: "0 !important",
  },
  p25:{
    padding: "25px",
  },
  px25:{
    padding: "25px 0 25px 0",
  },
  py25:{
    padding: "0 25px 0 25px",
  },
};

export default paddings;