import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout } from "./layouts";

import {
  Home as HomeView,
  CategorySelect as CategorySelectView,
  ProductList as ProductListView,
  ProductView,
  NotFound as NotFoundView,
  About as AboutView,
  Brochures as BrochuresView,
  Support as SupportView,
  Cart as CartView,
  Login as LoginView,
  Register as RegisterView,
  ProductSearch as ProductSearchView,
  // #placeholderimports
} from "./views";

const routes = [
  {
    component: HomeView,
    path: "/",
  },
  {
    component: CategorySelectView,
    path: "/products-category-select/:id/:mainSubTypeId?/:productTypeId?/:productSubType?/:productId?",
  },
  {
    component: ProductListView,
    path: "/products/:id",
  },
  {
    component: ProductView,
    path: "/product-view/:id",
  },
  { component: NotFoundView, path: "/not-found" },
  { component: AboutView, path: "/About" },
  { component: BrochuresView, path: "/Brochures" },
  { component: SupportView, path: "/Support" },
  { component: CartView, path: "/Cart" },
  { component: LoginView, path: "/Login" },
  { component: RegisterView, path: "/Register" },
  { component: ProductSearchView, path: "/search/:param" },
  // #placeholderroute
  {
    component: NotFoundView,
    path: "/not-found",
  },
];

const Routes = () => {
  return (
    <Switch>
      {routes.map((route) => (
        <RouteWithLayout
          component={route.component}
          exact
          layout={MainLayout}
          path={route.path}
          key={route.path}
        />
      ))}
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
