import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import BrochureItem from './components';
import { Section } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: "25px",
    paddingBottom: "55px",
  },
  bgImgWithText: {
    height: "100%",
    padding: "75px",
    backgroundImage: "url('images/home-folder-bg-2.png')",
    backgroundPosition: 'center', 
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat',
  },
  fullwidthGrid: {
    width: "100%",
  },
  mainGrid: {
    marginBottom: "25px",
  },
  gridRow: {
    marginTop: "25px",
  },
  Bigtitle: {
    fontWeight: 800,
  },
  smTitle: {

  },
}));

const catalogueRows = [
  [
    {
      text: "Catalogue Tube Access",
      image: "url('catalogues/tubeaccess.jpeg')",
    },
    {
      text: "Catalogue Flexgaz",
      image: "url('catalogues/flexgaz.jpeg')",
    },
    {
      text: "Catalogue APE",
      image: "url('catalogues/ape.jpeg')",
    },
  ],
  [
    {
      text: "Catalogue Frabo",
      image: "url('catalogues/frabo.jpeg')",
    },
    {
      text: "Catalogue Stelrad",
      image: "url('catalogues/stelrad.jpeg')",
    },
    {
      text: "Catalogue Flexitub",
      image: "url('catalogues/flexitub.jpeg')",
    },
  ]
];

const Brochures = () => {
  const classes = useStyles();
  
  return (
    <Section light>
      <Container className={classes.root}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid className={clsx(classes.mainGrid, classes.fullwidthGrid)} item xs={12}>
            <Box className={classes.bgImgWithText}>
              <Typography className={classes.smTitle} variant="subtitle2">Nouveauté</Typography>
              <Typography className={classes.Bigtitle} variant="h1">CATALOGUE PROMO<br/>JANVIER 2021</Typography>
              <Button color="primary" variant="contained">J'en profite</Button>
            </Box>
          </Grid>
          {catalogueRows.map(row => (
            <Grid className={classes.gridRow} container direction="row" justify="space-between" alignItems="center" spacing={3}>
              {
                row.map(element => (
                  <BrochureItem text={element.text} image={element.image} />
                ))
              }
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default Brochures;
