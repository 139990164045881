
const margins = {
  mx10: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  mt0: {
    marginTop: "0 !important",
  },
  mt10: {
    marginTop: "10px",
  },
  mt15: {
    marginTop: "15px",
  },
  mt20: {
    marginTop: "20px",
  },
  mt25: {
    marginTop: "25px",
  },
  mt35: {
    marginTop: "35px",
  },
  mb10: {
    marginBottom: "10px",
  },
  mb15: {
    marginBottom: "15px",
  },
  mb20: {
    marginBottom: "20px",
  },
  mb25: {
    marginBottom: "25px",
  },
  mb35: {
    marginBottom: "35px",
  },
};

export default margins;