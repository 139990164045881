import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import PinDropIcon from '@material-ui/icons/PinDrop';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import AvTimerIcon from '@material-ui/icons/AvTimer';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 800,
    marginBottom: '12px'
  },
  infoBlock: {
    marginBottom: '55px'
  }
}));

const CfiInfo = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.infoBlock}>
        <PinDropIcon />
        <Typography className={classes.title} variant="h4">
          Adresse
        </Typography>
        <Typography className={classes.adresse} variant="subtitle1">
          Rue Jean Gruslin 20
        </Typography>
        <Typography className={classes.adresse} variant="subtitle1">
          4460 Grâce-Hollogne
        </Typography>
      </Box>
      <Box className={classes.infoBlock}>
        <PhoneInTalkIcon />
        <Typography className={classes.title} variant="h4">
          Contact info
        </Typography>
        <Typography className={classes.adresse} variant="subtitle1">
          Tel: 04 384 62 90
        </Typography>
        <Typography className={classes.adresse} variant="subtitle1">
          Email: grace-hollogne@c-fi.be
        </Typography>
      </Box>
      <Box className={classes.infoBlock}>
        <AvTimerIcon />
        <Typography className={classes.title} variant="h4">
          Horaires
        </Typography>
        <Typography className={classes.adresse} variant="subtitle1">
          Du lundi au jeudi | 7h30-17h
        </Typography>
        <Typography className={classes.adresse} variant="subtitle1">
          Samedi | 7h30-14h
        </Typography>
        <Typography className={classes.adresse} variant="subtitle1">
          Dimanche | fermé
        </Typography>
        <Typography className={classes.adresse} variant="subtitle1">
          Vendredi | 7h30-13h et 14h30-17h
        </Typography>
      </Box>
    </>
  );
};

export default CfiInfo;
