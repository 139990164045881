import palette from '../palette';


const root = {
  color: palette.icon,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.03)'
  }
};
const muiIconButton = {
  root: root
};

export default muiIconButton;
