import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { CfiFamily, Section } from 'components';
import { Founders, VideoPresentation } from './components';

const useStyles = makeStyles((theme) => ({
  ...theme.common,
  mt25: theme.margins.mt25,
  fillWidth: {
    maxWidth: '100%'
  }
}));

const About = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Section light className={classes.root}>
        <Founders />
        <VideoPresentation />
      </Section>
      <Section>
        <CfiFamily />
      </Section>
    </React.Fragment>
  );
};

export default About;
