
const mainTypes = [
  { "ID": "45", "Name": "Chauffage", "PicName": "", icon: <img alt="chauffage icon" style={{ maxHeight: "20px" }} src="/product-category-icons/chauffage.svg" /> },
  { "ID": "46", "Name": "Sanitaire", "PicName": "", icon: <img alt="sanitaire icon" style={{ maxHeight: "20px" }} src="/product-category-icons/sanitaire.svg" /> },
  { "ID": "52", "Name": "Electricite", "PicName": "", icon: <img alt="electricite icon" style={{ maxHeight: "20px" }} src="/product-category-icons/electricite.svg" /> },
  { "ID": "47", "Name": "Eau Chaude Et Energie Renouvlable", "PicName": "", icon: <img alt="eau icon" style={{ maxHeight: "20px" }} src="/product-category-icons/eau.svg" /> },
  { "ID": "50", "Name": "Traitement d'Eau Et Pompes", "PicName": "", icon: <img alt="traitement icon" style={{ maxHeight: "20px" }} src="/product-category-icons/traitement.svg" /> },
  { "ID": "54", "Name": "Fixation", "PicName": "", icon: <img alt="fixation icon" style={{ maxHeight: "20px" }} src="/product-category-icons/fixation.svg" /> },
  { "ID": "49", "Name": "Installation", "PicName": "", icon: <img alt="installation icon" style={{ maxHeight: "20px" }} src="/product-category-icons/installation.svg" /> },
  { "ID": "51", "Name": "Outils Et Vetements", "PicName": "", icon: <img alt="outils icon" style={{ maxHeight: "20px" }} src="/product-category-icons/outils.svg" /> },
  { "ID": "48", "Name": "Ventilation", "PicName": "", icon: <img alt="ventilation icon" style={{ maxHeight: "20px" }} src="/product-category-icons/ventilation.svg" /> },
];

export default mainTypes;