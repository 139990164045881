import React from 'react';
import logo from 'assets/img/logo.png';
import logoLight from 'assets/img/logo-light.png';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: theme.palette.black.main,
    marginTop: '35px',
    marginBottom: '35px',
    height: 28
  }
}));

const Logo = ({ light }) => {
  const classes = useStyles();

  return (
    <img
      alt="C-fi_logo"
      className={classes.avatar}
      src={light ? logoLight : logo}
    />
  );
};

export default Logo;
