import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Container,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  Typography
} from '@material-ui/core';
import { Logo } from 'components';
import { NavLink } from 'react-router-dom';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.darkBG,
    color: theme.palette.white,
    padding: theme.spacing(4)
  },
  columnTitle: {
    color: theme.palette.white,
    fontWeight: 500
  },
  listItem: {
    color: '#b6bbb7'
  },
  footerAddress: {
    fontSize: '16px',
    color: '#b6bbb7'
  },
  underline: {
    marginTop: '25px',
    color: '#b6bbb7',
    ':placeholder': {
      color: 'red'
    },
    '&:before': {
      borderBottomColor: '#b6bbb7'
    }
  },
  textFieldPlaceholder: {
    color: '#b6bbb7'
  },
  ...theme.common
}));

const menuLinks = [
  {
    label: 'Acceuil',
    link: '/'
  },
  {
    label: 'A propos',
    link: '/about'
  },
  {
    label: 'Partenariat',
    link: '/partnership'
  },
  {
    label: 'Actualités',
    link: '/news'
  }
];

const productLink = [
  // {
  //   label: 'Nouveautés',
  //   link: '/products/new',
  // },
  // {
  //   label: 'Nos familles',
  //   link: '/familly',
  // },
  {
    label: 'Nos brochures',
    link: '/brochures'
  }
];

const supportLink = [
  {
    label: 'FAQs',
    link: '/faq'
  },
  {
    label: 'Contact',
    link: '/support'
  },
  // {
  //   label: 'Suivi de mon colis',
  //   link: '/track'
  // },
  {
    label: 'Conditions générales',
    link: '/general-terms'
  }
];

const Footer = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Container>
        <Grid container>
          <Logo light />
        </Grid>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item md={3}>
            <Typography className={classes.columnTitle} variant="h3">
              Adresse
            </Typography>
            <br />
            <Typography className={classes.footerAddress} variant="caption">
              Rue Jean Gruslin 20,
            </Typography>
            <br />
            <Typography className={classes.footerAddress} variant="caption">
              4460 Grâce-Hollogne
            </Typography>
            <br />
            <br />
            <a className={classes.link} href="tel:+3243846290">
              04 384 62 90
            </a>
            <br />
            <br />
            <a className={classes.link} href="mailto:grace-hollogne@c-fi.be">
              grace-hollogne@c-fi.be
            </a>
          </Grid>
          <Grid item md={2}>
            <Typography className={classes.columnTitle} variant="h3">
              Menu
            </Typography>
            <List>
              {menuLinks.map((link) => (
                <ListItem key={link.link} disableGutters>
                  <NavLink
                    className={classes.listItem}
                    to={link.link}
                    underline="hover"
                  >
                    {link.label}
                  </NavLink>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item md={2}>
            <Typography className={classes.columnTitle} variant="h3">
              Produits
            </Typography>
            <List>
              {productLink.map((link) => (
                <ListItem key={link.link} disableGutters>
                  <NavLink
                    className={classes.listItem}
                    to={link.link}
                    underline="hover"
                  >
                    {link.label}
                  </NavLink>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item md={2}>
            <Typography className={classes.columnTitle} variant="h3">
              Support
            </Typography>
            <List>
              {supportLink.map((link) => (
                <ListItem key={link.link} disableGutters>
                  <NavLink
                    className={classes.listItem}
                    to={link.link}
                    underline="hover"
                  >
                    {link.label}
                  </NavLink>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item md={3}>
            <Typography className={classes.columnTitle} variant="h3">
              Newsletter
            </Typography>
            <FormControl
              fullWidth
              className={clsx(classes.margin, classes.textField)}
            >
              <InputLabel
                className={classes.textFieldPlaceholder}
                htmlFor="standard-adornment"
              >
                Entrez votre adresse email
              </InputLabel>
              <Input
                id="standard-adornment"
                type={'text'}
                className={classes.underline}
                // onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      // onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
