import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const SnackbarContext = React.createContext({});

// Dont forget to add this at the root of your project around your <App />
export default class ProvideSnackbarContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      severity: 'success',
      message: 'Hello World',
      autoHideDuration: 6000,
      warningMessage: (message) =>
        this.setState({ severity: 'warning', message: message, open: true }),
      errorMessage: (message) =>
        this.setState({ severity: 'error', message: message, open: true }),
      successMessage: (message) =>
        this.setState({ severity: 'success', message: message, open: true }),
      handleClose: (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        this.setState({ open: false });
      }
    };
  }

  componentDidUpdate() {}

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <SnackbarContext.Provider value={this.state}>
          {this.props.children}
        </SnackbarContext.Provider>
        <Snackbar
          open={this.state.open}
          autoHideDuration={this.state.autoHideDuration}
          onClose={this.state.handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={this.state.handleClose}
            severity={this.state.severity}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}
