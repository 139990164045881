import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, ButtonGroup, IconButton } from '@material-ui/core';
import { TextField } from '@mui/material';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((_) => ({
  counterLabel: {
    backgroundColor: '#f4f6f8',
    border: '0 !important',
    borderColor: 'orange'
  }
}));

const Counter = ({ startValue, valueChangedHandler }) => {
  const classes = useStyles();
  const inputRef = React.useRef();
  const [counter, setCounter] = React.useState(startValue ?? 1);
  const [inputCounterValue, setInputCounterValue] = React.useState(0);
  const [isInEdit, setIsInEdit] = React.useState(false);

  const updateCounter = (newValue) => {
    setCounter(newValue);

    if (valueChangedHandler) {
      valueChangedHandler(newValue);
    }
  };

  const displayTextField = () => setIsInEdit(true);

  const quitEdit = () => {
    setIsInEdit(false);
    updateCounter(inputCounterValue);
  };

  React.useEffect(() => {
    if (isInEdit) {
      setInputCounterValue(counter);
      inputRef.current.focus();
    }
  }, [isInEdit, counter]);

  return (
    <ButtonGroup disableElevation variant="text" color="primary">
      <IconButton
        className={classes.counterLabel}
        color="primary"
        aria-label="remove"
        onClick={() => updateCounter(Math.max(1, +counter - 1))}
      >
        {' '}
        <RemoveIcon />{' '}
      </IconButton>
      <Button className={classes.counterLabel} onClick={displayTextField}>
        &nbsp;
        {isInEdit ? (
          <TextField
            inputRef={inputRef}
            sx={{
              backgroundColor: '#f4f6f8',
              border: '0 !important',
              borderColor: 'orange',
              padding: '0'
            }}
            size="small"
            variant="outlined"
            defaultValue={counter}
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={(e) => setInputCounterValue(e.target.value)}
            onBlur={quitEdit}
          />
        ) : (
          counter
        )}
        &nbsp;
      </Button>
      <IconButton
        className={classes.counterLabel}
        color="primary"
        aria-label="add"
        onClick={() => updateCounter(+counter + 1)}
      >
        {' '}
        <AddIcon />{' '}
      </IconButton>
    </ButtonGroup>
  );
};

export default Counter;
