import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Section } from 'components';
import {
  Button,
  Grid,
  IconButton,
  Typography,
  InputAdornment,
  InputLabel,
  TextField
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import validate from 'validate.js';
import { AuthContext } from 'contexts/AuthContext';

const schema = {
  'account-email': {
    presence: { allowEmpty: false, message: 'est obligatoire' },
    email: true
  },
  'account-password': {
    presence: { allowEmpty: false, message: 'est obligatoire' }
  }
};

const useStyles = makeStyles((theme) => ({
  ...theme.common,
  label: {
    fontWeight: 600,
    fontSize: '17px'
  },
  textField: {
    paddingTop: '12px'
  },
  loginForm: {
    marginTop: '70px',
    marginBottom: '135px'
  },
  alert: {
    marginBottom: theme.spacing(2)
  }
}));

const Login = () => {
  const classes = useStyles();
  let history = useHistory();
  const { login } = useContext(AuthContext);

  const [displayPwd, setDisplayPwd] = React.useState('password');
  const [displayError, setDisplayError] = React.useState(false);
  const [formState, setFormState] = React.useState({
    isValid: false,
    loading: false,
    values: {},
    touched: {},
    errors: {}
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const errors = validate(formState.values, schema);

    if (!errors) {
      setDisplayError(false);
      const result = await login(
        formState.values['account-email'],
        formState.values['account-password']
      );

      if (result) {
        history.push('/');
      } else {
        setDisplayError(true);
      }
    }
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = (field) => {
    return (
      formState.touched[field] &&
      formState.errors[field] &&
      formState.errors[field].length > 0
    );
  };

  return (
    <React.Fragment>
      <Section light>
        <form className={classes.loginForm} onSubmit={onSubmit}>
          <Grid container justify="center">
            <Grid item xs={10} md={3}>
              {displayError && (
                <Alert className={classes.alert} severity="error">
                  Erreur de connexion, vérifiez vote email ou mot de passe.
                </Alert>
              )}
              <InputLabel
                className={classes.label}
                shrink
                htmlFor="account-email"
              >
                Email address
              </InputLabel>
              <TextField
                id="account-email"
                name="account-email"
                variant="filled"
                className={classes.textField}
                inputProps={{ className: classes.textField }}
                fullWidth
                InputProps={{ disableUnderline: true }}
                required
                onChange={handleChange}
                helperText={
                  hasError('account-email')
                    ? formState.errors['account-email'][0]
                    : ''
                }
              />
              <br />
              <br />
              <InputLabel
                className={classes.label}
                shrink
                htmlFor="account-password"
              >
                Password
              </InputLabel>
              <TextField
                id="account-password"
                name="account-password"
                variant="filled"
                className={classes.textField}
                type={displayPwd}
                fullWidth
                required
                onChange={handleChange}
                helperText={
                  hasError('account-password')
                    ? formState.errors['account-password'][0]
                    : ''
                }
                inputProps={{ className: classes.textField }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(_) =>
                          setDisplayPwd(
                            displayPwd === 'text' ? 'password' : 'text'
                          )
                        }
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {displayPwd === 'text' ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <br />
              <br />
              <br />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.mainButton}
                disabled={formState.isValid === false || formState.loading}
                fullWidth
                // onClick={e => setAnchorEl(e.currentTarget)}
              >
                Connexion
              </Button>
              <Link to="/register">
                <Typography variant="caption">Créer un compte</Typography>
              </Link>
            </Grid>
          </Grid>
        </form>
      </Section>
    </React.Fragment>
  );
};

export default Login;
