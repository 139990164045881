import { Container, Paper, Grid, Hidden } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import PopoverMenu from "./components";
import { useHistory, NavLink } from "react-router-dom";
import links from "common/links";
import { InputBase, IconButton, Stack } from "@mui/material";
import { Search } from "@mui/icons-material";
import { getProductsByName } from "cfiAPI";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  link: {
    color: theme.palette.black,
    margin: "15px",
    fontWeight: 600,
  },
  flexGrow: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  secondPaper: {
    marginTop: "20px",
    marginBottom: "20px",
    paddingLeft: "15px",
  },
}));

const NavBar = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Paper elevation={0}>
        <Grid container>
          <Grid item>
            <PopoverMenu />
            &nbsp;&nbsp;&nbsp;
            <Hidden smDown>
              {links.map((link) => (
                <NavLink
                  key={link.name}
                  className={classes.link}
                  to={link.href}
                >
                  {link.name}
                </NavLink>
              ))}
            </Hidden>
          </Grid>
          <Hidden xsDown>
            <div style={{ flexGrow: 1 }} />
            <SearchTextField />
          </Hidden>
        </Grid>
      </Paper>
      <Hidden smUp>
        <Paper className={classes.secondPaper} elevation={0}>
          <SearchTextField />
        </Paper>
      </Hidden>
    </Container>
  );
};

const SearchTextField = () => {
  const classes = useStyles();
  let history = useHistory();

  const [search, setSearch] = useState("");

  const searchClicked = async () => {
    if (!search || search.length < 2) {
      alert("Critère de recherche trop court");
      return;
    }

    history.push(`/search/${search}`);
  };

  return (
    <>
      <Stack direction="row">
        <InputBase
          className={classes.input}
          placeholder="Rechercher"
          inputProps={{ "aria-label": "rechercher" }}
          fullWidth
          onChange={(e) => setSearch(e.target.value)}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="Rechercher"
          onClick={searchClicked}
        >
          <Search />
        </IconButton>
      </Stack>
    </>
  );
};

export default NavBar;
