import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Routes from './Routes';

// import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import './assets/css/custom.css';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    );
  }
}

export default App;
