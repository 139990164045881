import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Alert, Typography } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import CfiTextField from '../CfiTextField';
import { SnackbarContext } from 'contexts/SnackbarContext';
import { insertWebUser, getUserLogin } from 'cfiAPI';

const useStyles = makeStyles((theme) => ({
  root: {},
  loginForm: {
    marginBottom: theme.spacing(12)
  },
  submitBtn: {
    marginTop: theme.spacing(4)
  },
  ...theme.common,
  ...theme.margins
}));

const initialValues = {
  name: undefined,
  email: undefined,
  phone: undefined,
  company: undefined
};

const fields = [
  { title: 'Information' },
  { name: 'name', label: 'Nom', sizes: { xs: 12, sm: 12 } },
  { name: 'company', label: 'Société', sizes: { xs: 12, sm: 12 } },
  { name: 'email', label: 'Adresse email', sizes: { xs: 12, sm: 6 } },
  {
    name: 'phone',
    label: 'Numéro de téléphone',
    sizes: { xs: 12, sm: 6 },
    type: 'number'
  }
];

const validationSchema = yup.object({
  name: yup.string('Entrez votre nom').required('Ce champ est obligatoire'),
  company: yup
    .string('Entrez le nom de la société')
    .required('Ce champ est obligatoire'),
  email: yup
    .string('Entrez votre adresse mail.')
    .email('Adresse non valide')
    .required('Ce champ est obligatoire'),
  phone: yup
    .number('Entrez votre numéro de téléphone')
    .required('Ce champ est obligatoire')
    .positive('Entrez un numéro de téléphone valide')
    .integer('Entrez un numéro de téléphone valide')
});

const WebUserForm = forwardRef(({ goBack }, ref) => {
  const classes = useStyles();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = React.useState(false);

  const { warningMessage } = React.useContext(SnackbarContext);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      customerID: localStorage.getItem('CFI-TEMP_NEW_USER_COMPANY_ID')
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setDisplayErrorMessage(false);

      getUserLogin(values.email).then((doesExist) => {
        if (doesExist.data === -1) {
          warningMessage(
            'Cette adresse email est déjà utilisé dans le système.'
          );
          return;
        }

        insertWebUser(values)
          .catch((err) =>
            alert("Une erreur s'est produite. Reessayez plus tard")
          )
          .then((resp) => {
            // Returns -1 if it did not work
            if (resp.data === -1) {
              setDisplayErrorMessage(true);
              return;
            }

            setIsFormSubmitted(true);
          });
      });
    }
  });

  return (
    <form
      className={classes.loginForm}
      onSubmit={formik.handleSubmit}
      ref={ref}
    >
      <Grid container justify="center">
        {isFormSubmitted && (
          <Grid item xs={12} md={5}>
            <Alert severity="success" sx={{ mb: 2 }}>
              Votre demande de compte à bien été reçue. Vous recevrez un mail
              dans les 24h pour vos accès.
            </Alert>
          </Grid>
        )}

        {isFormSubmitted === false && (
          <Grid item xs={12} md={5}>
            <Typography onClick={goBack} variant="caption">
              Retour
            </Typography>

            {displayErrorMessage && (
              <Alert severity="error" sx={{ mt: 2 }}>
                Une erreur s'est produite. Vérifiez vos informations et
                réessayez plus tard. Si cela persiste, contacter un admin.
              </Alert>
            )}

            <Grid container justify="flex-start" spacing={1}>
              {fields.map((field) => {
                if (field.visible) {
                  if (field.visible(formik.values) === false) {
                    return null;
                  }
                }
                if (field.empty) {
                  return <Grid item {...field.sizes} />;
                }
                if (field.title) {
                  return (
                    <Grid item xs={12} className={classes.mt35}>
                      <Typography variant="h4" className={classes.h3Title}>
                        {field.title}
                      </Typography>
                    </Grid>
                  );
                }
                return (
                  <Grid item {...field.sizes}>
                    <CfiTextField
                      name={field.name}
                      label={field.label}
                      type={field.type}
                      value={formik.values[field.name]}
                      handleChange={formik.handleChange}
                      touched={formik.touched[field.name]}
                      errors={formik.errors[field.name]}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Button
              className={classes.submitBtn}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
            >
              Confirmer
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
});

export default WebUserForm;
