import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Grid, Typography } from '@material-ui/core';
// import SelectLanguage from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.darkBG,
    padding: '8px'
  },
  textMessage: {
    color: theme.palette.white
  }
}));

const TopMessage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Grid
          alignItems="center"
          justifyContent="center"
          container
          spacing={10}
        >
          <Grid item>
            <Typography
              className={classes.textMessage}
              variant="caption"
              align="center"
            >
              Zone pour ecrire une phrase promotionelle avec un appel à l'action
            </Typography>
          </Grid>
          {/* <Grid item>
            <SelectLanguage />
          </Grid> */}
        </Grid>
      </Container>
    </div>
  );
};

export default TopMessage;
