import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import getCompleteUrlForImage from "helpers/imageUrlHelper";
import { AddToCartButton } from "components";

const useStyles = makeStyles((theme) => ({
  mainButton: {
    ...theme.common.mainButton,
    color: theme.palette.white,
    marginBottom: "15px",
  },
  img: {
    maxHeight: "125px",
  },
  ...theme.common,
  actionCell: {
    padding: "35px 0px 35px 0px",
  },
  clickable: {
    cursor: "pointer",
  },
}));

const LoadingRow = () => (
  <TableRow>
    <TableCell component="th" scope="row" align="right">
      <Skeleton variant="rect" height={75} />
    </TableCell>
    <TableCell align="left">
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
    </TableCell>
    <TableCell align="center">
      <Skeleton variant="rect" />
    </TableCell>
  </TableRow>
);

const ProductRow = ({ loading, productList }) => {
  const classes = useStyles();
  let history = useHistory();

  let [limitDisplay, setLimitDisplay] = useState(15);

  const selectProductToView = async (productId) => {
    history.push("/product-view/" + productId);
  };

  if (loading) {
    return <LoadingRow />;
  }

  return (
    <>
      {productList.map(
        (product, index) =>
          index < limitDisplay && (
            <TableRow key={product.ID}>
              <TableCell
                component="th"
                scope="row"
                align="center"
                className={classes.clickable}
                onClick={() => selectProductToView(product.ID)}
              >
                <img
                  className={classes.img}
                  alt={product.ID}
                  src={getCompleteUrlForImage(product.PicName)}
                />
              </TableCell>
              <TableCell
                className={classes.clickable}
                onClick={() => selectProductToView(product.ID)}
                align="left"
              >
                <Typography variant="caption">{product.Name}</Typography>
                <Typography variant="h4">{product.Name}</Typography>
                <Typography variant="subtitle2">
                  Réference {product.Reference}
                </Typography>
                <Typography className={classes.priceLabel}>
                  {product.UnitPrice} €
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Paper className={classes.actionCell}>
                  <AddToCartButton product={product} />
                  <Typography variant="subtitle2">
                    Disponibilité:{" "}
                    <span className={classes.priceLabel}>
                      En stock ({product.StockQuantity})
                    </span>
                  </Typography>
                  <Typography
                    variant="caption"
                    className={classes.link}
                    onClick={() => selectProductToView(product.ID)}
                  >
                    Voir plus...
                  </Typography>
                </Paper>
              </TableCell>
            </TableRow>
          )
      )}
      {productList.length > limitDisplay && (
        <Box sx={{ px: 3, py: 1.5 }}>
          <Button onClick={() => setLimitDisplay(limitDisplay + 15)}>
            Voir plus...
          </Button>
        </Box>
      )}
    </>
  );
};

export default ProductRow;
