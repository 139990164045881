import React from "react";
import {
  getUserSession,
  getUserInfoByWebUser,
  getSnotaBasketByCustomerId,
} from "cfiAPI";

export const AuthContext = React.createContext({});

// Dont forget to add this at the root of your project around your <App />
export default class ProvideAuthContext extends React.Component {
  constructor(props) {
    super(props);

    const baseCustomerId = localStorage.getItem("CFI-CustomerId");
    const baseWebId = localStorage.getItem("CFI-WebId");
    const baseSessionId = localStorage.getItem("CFI-SessionId");

    this.state = {
      update: true,
      isLoggedIn: Boolean(baseSessionId && baseWebId),
      sessionId: baseSessionId,
      webId: baseWebId,
      customerId: baseCustomerId,
      guid: this.guid,
      userInfo: async (webUserId, sessionId) => {
        new Promise((resolver) => {
          console.log("userInfo");
          getUserInfoByWebUser(webUserId, sessionId)
            .then(async (userInfoResponse) => {
              console.log("userInfoResponse.data", userInfoResponse.data);
              const customerId = userInfoResponse.data;
              if (customerId !== -1) {
                resolver(true);
              } else {
                resolver(false);
              }
            })
            .catch((err) => {
              console.error(err);
              resolver(false);
            });
        });
      },
      login: async (username, password) => {
        return new Promise((resolver) => {
          getUserSession(username, password)
            .then((response) => {
              const { data } = response;
              if (data && data.length > 0) {
                localStorage.setItem("CFI-WebId", data[0].WebUserID);
                localStorage.setItem("CFI-CustomerId", data[0].ID);
                localStorage.setItem("CFI-SessionId", data[0].SessionID);

                this.setState({
                  customerId: data[0].ID,
                  webId: data[0].WebUserID,
                  isLoggedIn: true,
                });

                resolver(true);
              } else {
                resolver(false);
              }
            })
            .catch((err) => {
              resolver(false);
              console.log("Error", err);
            });
        });
      },
      logout: async () => {
        localStorage.removeItem("CFI-WebId");
        localStorage.removeItem("CFI-CustomerId");
        localStorage.removeItem("CFI-SessionId");
        this.setState({ isLoggedIn: false, webId: null, customerId: null });
      },
    };
  }

  guid = () => {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  };

  componentDidUpdate() {}

  componentDidMount() {
    getSnotaBasketByCustomerId(this.state.customerId)
      .then(() => {
        // used to launch the CartContext that will fetch the cart
        this.setState({ update: !this.state.update });
      })
      .catch(() => {
        localStorage.removeItem("CFI-WebId");
        localStorage.removeItem("CFI-CustomerId");
        localStorage.removeItem("CFI-SessionId");
        this.setState({ isLoggedIn: false, webId: null, customerId: null });
      });

    // getUserInfoByWebUser(this.state.webId, this.state.sessionId)
    //   .then(async (userInfoResponse) => {
    //     const customerId = userInfoResponse.data;
    //     if (customerId != -1) {
    //       localStorage.setItem('CFI-CustomerId', customerId);
    //       this.setState({
    //         customerId,
    //         isLoggedIn: true
    //       });
    //     } else {
    //       localStorage.removeItem('CFI-CustomerId');
    //       localStorage.removeItem('CFI-SessionId');
    //       this.setState({ isLoggedIn: false, webId: null, customerId: null });
    //     }
    //   })
    //   .catch((err) => {
    //     localStorage.removeItem('CFI-CustomerId');
    //     localStorage.removeItem('CFI-SessionId');
    //     this.setState({ isLoggedIn: false, webId: null, customerId: null });
    //   });
  }

  render() {
    return (
      <React.Fragment>
        <AuthContext.Provider value={this.state}>
          {this.props.children}
        </AuthContext.Provider>
      </React.Fragment>
    );
  }
}
