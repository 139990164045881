import { useState } from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useCart from "../hooks/useCart";
import Counter from "./Counter";
import { Button, Grid } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function AddToCartButton({ product }) {
  const { getProductQt, addItem, updateQtWithId } = useCart();
  const { isLoggedIn } = useAuth();
  let history = useHistory();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const addProduct = (product) => {
    console.log("isLoggedIn", isLoggedIn);
    if (isLoggedIn === false) {
      setOpen(true);
      return;
    }

    addItem(product);
  };

  const udpateQuantityHandler = (newValue) =>
    updateQtWithId(product.ID, newValue);

  return getProductQt(product.ID) === 0 ? (
    <>
      <Grid item>
        <Button
          sx={{ mb: 2 }}
          onClick={(_) => addProduct(product)}
          variant="contained"
          color="primary"
        >
          Ajouter au panier
        </Button>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Connection obligatoire "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous devez vous connecter pour pouvoir ajouter un produit à votre
            panier. Voulez-vous vous connecter maintenant ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Non</Button>
          <Button
            onClick={() => history.push("/login")}
            autoFocus
            variant="contained"
          >
            Se connecter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <Grid item>
      <Counter
        startValue={getProductQt(product.ID)}
        valueChangedHandler={(newValue) => udpateQuantityHandler(newValue)}
      />
    </Grid>
  );
}

export default AddToCartButton;
