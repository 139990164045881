import React from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  TableContainer,
  Paper,
  Table,
  TableBody,
  Grid,
} from "@mui/material";
import { Section } from "components";
import ProductRow from "../ProductList/components/ProductRow";
import { getProductsByName } from "cfiAPI";

const Products = () => {
  let { param } = useParams();

  const [productList, setProductList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const response = await getProductsByName(param);

      setProductList(response.data);
      setLoading(false);
    };

    getData();
  }, [param]);

  return (
    <Section light>
      <Container>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              <ProductRow loading={loading} productList={productList} />
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && productList.length === 0 && (
          <Grid sx={{ pt: 3, pb: 6 }}>Aucun produit à afficher</Grid>
        )}
      </Container>
    </Section>
  );
};

export default Products;
