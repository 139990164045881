import React from "react";
import clsx from "clsx";
import { Counter } from "components";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CartContext } from "contexts/CartContext";
import getCompleteUrlForImage from "helpers/imageUrlHelper";

import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  ...theme.common,
  root: {},
  cardContent: {
    paddingTop: "24px",
  },
  cartProductImg: {
    maxWidth: "100px",
    maxHeight: "100px",
  },
  cartProductName: {
    maxWidth: "100%",
    paddingRight: "25px",
  },
  centerElements: {
    textAlign: "center",
  },
  gridItem: {
    maxWidth: "100%",
  },
}));

const CartItem = ({ productInfo, deleteItem }) => {
  const classes = useStyles();
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { updateQt } = React.useContext(CartContext);

  const [confirmDelete, setConfirmDelete] = React.useState(false);

  return (
    <Grid item className={classes.gridItem}>
      <Card>
        <CardContent className={classes.cardContent}>
          <Grid
            container
            direction={largeScreen ? "row" : "column"}
            justify="space-around"
            alignItems="center"
            spacing={largeScreen ? 0 : 1}
          >
            <Grid item className={classes.textAlignCenter} md={2}>
              <img
                alt="produit"
                className={clsx(classes.centerElements, classes.cartProductImg)}
                src={getCompleteUrlForImage(productInfo.PicName)}
                loading="lazy"
              />
            </Grid>
            <Grid item md={4}>
              <Typography
                variant="h4"
                className={classes.cartProductName}
                title={productInfo.ProductName}
              >
                {productInfo.ProductName}
              </Typography>
            </Grid>
            <Grid item md={4} className={classes.centerElements}>
              <Counter
                startValue={productInfo.ProductQuantity}
                valueChangedHandler={(newValue) =>
                  updateQt(productInfo.DetailID, newValue)
                }
              />
              <br />
              <br />
              <Typography
                noWrap
                className={clsx(classes.priceLabel, classes.mx10)}
                variant="h5"
              >
                {productInfo.ProductPrice} €
              </Typography>
            </Grid>
            <Grid item md={2} className={classes.centerElements}>
              {confirmDelete ? (
                <>
                  <IconButton
                    aria-label="delete"
                    onClick={(_) => setConfirmDelete(false)}
                  >
                    <CancelIcon color="inherit" />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={(_) => deleteItem(productInfo.DetailID)}
                  >
                    <CheckCircleIcon color="error" />
                  </IconButton>
                </>
              ) : (
                <IconButton
                  aria-label="delete"
                  onClick={(_) => setConfirmDelete(true)}
                >
                  <ClearIcon color="primary" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CartItem;
