import React from "react";
import {
  Box,
  Divider,
  Table,
  TableRow,
  TableCell,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const props = [
  { name: "Reference", label: "Référence", unit: "" },
  { name: "EAN", label: "Code EAN", unit: "" },
  { name: "Brand", label: "Marque", unit: "" },
  { name: "Weight", label: "Poids", unit: "Kg" },
  { name: "Width", label: "Largeur", unit: "cm" },
  { name: "Height", label: "Hauteur", unit: "cm" },
  { name: "Depth", label: "Profondeur", unit: "cm" },
  { name: "ColorCode", label: "Couleur", unit: "" },
];

const ExtraInfoInTab = ({ product }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        indicatorColor="primary"
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label="Information additionnelles" {...a11yProps(0)} />
      </Tabs>
      <Divider variant="fullWidth" />
      <TabPanel value={value} index={0}>
        <Box>
          <Table>
            {props.map(
              (prop, index) =>
                product[prop.name] && (
                  <TableRow
                    key={`${prop.name}-${index}`}
                    alignItems="flex-start"
                    divider
                  >
                    <TableCell>
                      <Typography variant="h5">{prop.label}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h4">
                        {product[prop.name]} {prop.unit}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
            )}
          </Table>
        </Box>
      </TabPanel>
    </>
  );
};

export default ExtraInfoInTab;
