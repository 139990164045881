import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  h3WhiteTitle: {
    fontWeight: 600,
    color: theme.palette.white,
  },
  h3Primary: {
    fontWeight: 600,
    color: theme.palette.orange,
  },
  gridRow: {
    paddingTop: "25px",
  },
  image: {
    borderRadius: 25,
    width: "100%",
    objectFit: "cover",
    maxHeight: "300px",
  },
  bgImgWithText: {
    height: "100%",
    padding: "25px",
    backgroundImage: "url('images/home-folder-bg-2.png')",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  imgSubGrid: {
    maxWidth: "100%",
    objectFit: "cover",
  },
  primaryOrangeBG: {
    padding: "25px",
    backgroundColor: theme.palette.orange,
  },
  secondaryBG: {
    marginTop: "25px",
    padding: "25px",
    backgroundImage: "url('images/home-folder-bg-3.png')",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  ...theme.common,
}));

const CfiFamily = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.gridRow}
        spacing={3}
      >
        <Grid item md={6} xs={12}>
          <img
            alt="depot"
            className={classes.image}
            src="images/home-entrepot.jpeg"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography className={classes.h3Title} variant="h4">
            C-fi est une entreprise familiale et est l'oeuvre de la deuxième
            génération.
          </Typography>
          <Typography className={classes.bodyOfText} variant="body1">
            Nous livrons vos commandes de manière rapide et sûre depuis et vers
            l’adresse de n’importe quel professionnel en Belgique.
            <br />
            <br />
            Nous vous proposons un service de qualité optimale pour la livraison
            de vos commandes en 24 heures. Même le samedi.
            <br />
            <br />
            Aujourd'hui, C-fi est le partenaire fidèle des professionnels du
            secteur.
            <br />
            <br />
            Vous pouvez compter sur un service global et sur mesure.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="stretch"
        className={classes.gridRow}
        spacing={3}
      >
        <Grid item md={6} xs={12}>
          <Box className={classes.bgImgWithText}>
            <Typography className={classes.h3Title} variant="h4">
              Une large gamme de produits
            </Typography>
            <Typography className={classes.bodyOfText} variant="body1">
              Nos produits s'adressent exclusivement aux professionnels. Nous
              avons développé une véritable expertise et un réseau de
              fournisseurs qui nous permettent de répondre aux besoins de nos
              clients en leur offrant le meilleur rapport qualité/prix. C-Fi
              offre aujourd'hui un large choix de produits et de matériaux.
              Consultez les gammes de produits disponibles.
            </Typography>
            <Button
              className={classes.darkRoundedButton}
              variant="contained"
              href="/brochures"
            >
              Nos Produits
            </Button>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              className={classes.primaryOrangeBG}
            >
              <Grid item xs={9} className={classes.subGrid}>
                <Typography className={classes.h3Title} variant="h4">
                  Nos brochures
                </Typography>
                <Typography variant="body1">
                  Consultez aisément nos brochures en ligne. Présentation de nos
                  produits.
                </Typography>
                <Button
                  className={classes.darkRoundedButton}
                  variant="contained"
                  href="/brochures"
                >
                  Nos Produits
                </Button>
              </Grid>
              <Grid item xs={3}>
                <img
                  alt="folder"
                  className={classes.imgSubGrid}
                  src="images/home-folder-bg.png"
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              className={classes.secondaryBG}
            >
              <Grid item xs={9} className={classes.subGrid}>
                <Typography className={classes.h3WhiteTitle} variant="h4">
                  Devenez
                </Typography>
                <Typography className={classes.h3Primary} variant="h3">
                  PARTENAIRE C-FI
                </Typography>
                <Button
                  className={classes.mainRoundedButton}
                  variant="contained"
                >
                  En savoir plus
                </Button>
              </Grid>
              <Grid item xs={3}>
                <img
                  alt="folder background"
                  className={classes.imgSubGrid}
                  src="images/home-folder-bg.png"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CfiFamily;
