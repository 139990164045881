import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Alert, Typography } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import CfiTextField from '../CfiTextField';
import { getCustomerByVat } from 'cfiAPI';

const useStyles = makeStyles((theme) => ({
  root: {},
  loginForm: {
    marginBottom: theme.spacing(12)
  },
  submitBtn: {
    marginTop: theme.spacing(4)
  },
  ...theme.common,
  ...theme.margins
}));

const initialValues = { vat: 'BE' };

const fields = [
  { title: 'Information' },
  { name: 'vat', label: 'TVA', sizes: { xs: 12, sm: 12 } }
];

const validationSchema = yup.object({
  vat: yup
    .string('Entrez votre numéro de TVA')
    .required('Ce champ est obligatoire')
});

const NewWebUserForm = forwardRef(({ goBack, goToOtherForm }, ref) => {
  const classes = useStyles();
  const [displayErrorMessage, setDisplayErrorMessage] = React.useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setDisplayErrorMessage(false);

      const { vat } = values;
      const t = await getCustomerByVat(vat);

      if (t.length > 0) {
        localStorage.setItem('CFI-TEMP_NEW_USER_COMPANY_ID', t[0]['ID']);
        goToOtherForm('NEWWEBUSER-FORM');
      } else {
        setDisplayErrorMessage(true);
      }
    }
  });

  return (
    <form
      className={classes.loginForm}
      onSubmit={formik.handleSubmit}
      ref={ref}
    >
      {displayErrorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Aucun numéro de TVA correspondant à votre recherche. Vérifiez votre
          entré ou{' '}
          <Typography
            varian="caption"
            sx={{ display: 'block', mt: 0.5 }}
            onClick={() => goToOtherForm('NEW')}
            className={classes.cursorHand}
          >
            Créer un compte ?
          </Typography>
        </Alert>
      )}
      <Grid container justify="center">
        <Grid item xs={12} md={5}>
          <Typography onClick={goBack} variant="caption">
            Retour
          </Typography>

          <Grid container justify="flex-start" spacing={1}>
            {fields.map((field) => {
              if (field.visible) {
                if (field.visible(formik.values) === false) {
                  return null;
                }
              }
              if (field.empty) {
                return <Grid item {...field.sizes} />;
              }
              if (field.title) {
                return (
                  <Grid item xs={12} className={classes.mt35}>
                    <Typography variant="h4" className={classes.h3Title}>
                      {field.title}
                    </Typography>
                  </Grid>
                );
              }
              return (
                <Grid item {...field.sizes}>
                  <CfiTextField
                    name={field.name}
                    label={field.label}
                    type={field.type}
                    value={formik.values[field.name]}
                    handleChange={formik.handleChange}
                    touched={formik.touched[field.name]}
                    errors={formik.errors[field.name]}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Button
            className={classes.submitBtn}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
          >
            Confirmer
          </Button>
        </Grid>
      </Grid>
    </form>
  );
});

export default NewWebUserForm;
