import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.black
  },
  darkSection: {
  },
  lighSection: {
    backgroundColor: theme.palette.white,
  },
  container: {
    paddingTop: "35px",
    paddingBottom: "35px",
  },
}));


const Section = ({ light, children, className }) => {
  const classes = useStyles();

  return (
    <section className={clsx(classes.root, light ? classes.lighSection : classes.darkSection, className)}>
      <Container className={classes.container}>
        {children}
      </Container>
    </section>
  );
};

export default Section;