import React, { Component } from 'react';
import App from './App';
import { ThemeProvider } from '@material-ui/styles';
import {
  createTheme,
  ThemeProvider as NewThemeProvider
} from '@mui/material/styles';
import theme from './theme';

import ErrorBoundary from './ErrorBoundary';
import ProvideAuthContext from 'contexts/AuthContext';
import ProvideCartContext from 'contexts/CartContext';
import ProvideSnackbarContext from 'contexts/SnackbarContext';

const newTheme = createTheme(theme);

class AppProvider extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <NewThemeProvider theme={newTheme}>
          <ProvideAuthContext>
            <ProvideSnackbarContext>
              <ProvideCartContext>
                <ErrorBoundary>
                  <App />
                </ErrorBoundary>
              </ProvideCartContext>
            </ProvideSnackbarContext>
          </ProvideAuthContext>
        </NewThemeProvider>
      </ThemeProvider>
    );
  }
}

export default AppProvider;
