import { colors } from '@material-ui/core';

const root = {
  backgroundColor: colors.grey[50]
};
const muiTableHead = {
  root: root
};

export default muiTableHead;
