import palette from '../palette';
import typography from '../typography';

const root = {
  ...typography.body1,
  borderBottom: `1px solid ${palette.divider}`
};
const muiTableCell = {
  root: root
};

export default muiTableCell;
