import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  bgImgWithText: {
    height: "100%",
    padding: "100px",
    backgroundPosition: 'center', 
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat',
  },
  fullwidthGrid: {
    width: "100%",
  },
  text: {
    marginTop: '8px',
  },
}));

const BrochureItem = ({ text, image }) => {
  const classes = useStyles();
  
  return (
    <Grid item xs={12} md={4}>
      <Box className={clsx(classes.bgImgWithText, classes.fullwidthGrid)} style={{ backgroundImage: image }}/>
      <Typography className={classes.text} align="center"variant="subtitle2">{text}</Typography>
    </Grid>
  );
};

export default BrochureItem;