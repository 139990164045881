const links = [
  { name: 'Accueil', href: '/' },
  { name: 'À propos', href: '/about' },
  { name: 'Nos Borchures', href: '/brochures' },
  // { name: "Actualités", href: "/news" },
  // { name: "Partenariat", href: "/partners" },
  { name: 'Support', href: '/support' }
];

export default links;
