import React from "react";
import {
  insertSnotaDetail,
  deleteSnotaDetail,
  getSnotaBasketByCustomerId,
  updateSnotaDetail,
  confirmSnotaBasket,
} from "cfiAPI";
import { AuthContext } from "./AuthContext";

export const CartContext = React.createContext({});

// Dont forget to add this at the root of your project around your <App />
export default class ProvideCartContext extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      products: [],
      addItem: (product) => {
        return new Promise((resolver) => {
          console.log("product", product);
          insertSnotaDetail(Number(product.ID), "1", product.PriceHT, "50")
            .then(() => this.reloadCart())
            .then((res) => resolver(res));
        });
      },
      updateQtWithId: (productId, qt) =>
        this.state.updateQt(this.state.getProductDetailID(productId), qt),
      updateQt: (detailId, qt) => {
        return new Promise((resolver) => {
          updateSnotaDetail(detailId, qt)
            .then((response) => {
              const { data } = response;

              console.log("data", data);

              if (data === 0) {
                this.reloadCart().then((res) => resolver(res));
              }
            })
            .catch((err) => {
              resolver(false);
              console.log("Error", err);
            });
        });
      },
      deleteItem: (detailId) => {
        return new Promise((resolver) => {
          deleteSnotaDetail(detailId)
            .then((response) => {
              const { data } = response;

              if (data === 0) {
                this.setState({
                  products: this.state.products.filter(
                    (item) => item.DetailID !== detailId
                  ),
                });
                this.reloadCart();
              }

              resolver(true);
            })
            .catch((err) => {
              resolver(false);
              console.log("Error", err);
            });
        });
      },
      getProductDetailID: (id) =>
        this.state.products.filter((item) => item.ProductID === id)[0]
          ?.DetailID ?? 0,
      getProductQt: (id) =>
        this.state.products.filter((item) => item.ProductID === id)[0]
          ?.ProductQuantity ?? 0,
      getCartCount: () => this.state.products.length,
      getCartHTVA: () => {
        if (this.state.products.length > 0) {
          return this.state.products.reduce(
            (a, b) => a + this.state.parseProductPrice(b.ProductPrice),
            0
          );
        }

        return "0.00";
      },
      getCartPrice: () => {
        if (this.state.products.length > 0) {
          return this.state.products[0].Total;
        }

        return "0.00";
      },
      getCartVAT: () =>
        this.state.parseProductPrice(this.state.getCartTotal()) -
        this.state.getCartHTVA(),
      getCartDiscount: () =>
        this.state.products.reduce(
          (a, b) => a + this.state.getItemDiscount(b.ID),
          0
        ),
      getCartTotal: () => {
        if (this.state.products.length > 0) {
          return this.state.products[0].Total;
        }

        return "0.00";
      },
      getItemDiscount: (id) => {
        const cartItem = this.state.products.filter(
          (item) => item.ID === id
        )[0];

        if (cartItem) {
          // const nbre = parseFloat(cartItem.UnitPrice.replace(',', '.'));
          // const vat = Number(cartItem.TypeDiscountPerc);
          // return nbre * cartItem.qt * (vat / 100);
        }

        return 0;
      },
      getItemVat: (id) => {
        const cartItem = this.state.products.filter(
          (item) => item.ID === id
        )[0];

        if (cartItem) {
          // const nbre = parseFloat(cartItem.UnitPrice.replace(',', '.'));
          // const vat = Number(cartItem.TypeVatPerc);
          // return nbre * cartItem.qt * (vat / 100);
        }

        return 0;
      },
      getItemPrice: (id) => {
        const cartItem = this.state.products.filter(
          (item) => item.ID === id
        )[0];

        if (cartItem) {
          const nbre = parseFloat(cartItem.ProductPrice.replace(",", "."));
          return nbre * cartItem.qt;
        }

        return 0;
      },
      parseProductPrice: (productPrice) => {
        const nbre = parseFloat(productPrice.replace(",", "."));
        return nbre;
      },
      confirmSnotaBasket: (reference) => {
        return new Promise((resolver) => {
          confirmSnotaBasket(reference)
            .then((response) => {
              const { data } = response;

              console.log("data", data);

              if (data === 0) {
                return this.reloadCart().then((res) => resolver(res));
              }
              resolver(false);
            })
            .catch((err) => {
              resolver(false);
              console.log("Error", err);
            });
        });
      },
    };
  }

  reloadCart() {
    return new Promise((resolver) => {
      getSnotaBasketByCustomerId(this.context.customerId)
        .then((t) => {
          this.setState({ products: t.data });
          resolver(true);
        })
        .catch((err) => {
          resolver(false);
          console.log("Error", err);
        });
    });
  }

  // Used to know when the user gets from the state NOT logged in into the state Logged in
  loggedIn = false;
  componentDidUpdate(prevProps) {
    // The user logs in
    if (this.loggedIn === false && this.context.isLoggedIn) {
      this.loggedIn = true;
      this.reloadCart();
    }

    // Logout
    if (this.loggedIn && this.context.isLoggedIn === false) {
      this.loggedIn = true;
      console.log("LOG OUT");
    }
  }

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <CartContext.Provider value={this.state}>
          {this.props.children}
        </CartContext.Provider>
      </React.Fragment>
    );
  }
}
