import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography } from '@material-ui/core';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const useStyles = makeStyles(theme => ({
  carousel: {
    width: "100%",
    height: "400px",
    objectFit: "cover",
  },
  textOverCarousel: {
    paddingLeft: "125px",
    color: theme.palette.white,
    position: 'absolute',
    top: '50%',
    transform: 'translate(0%, -50%)',
  },
  carouselSmallTitle: {
    color: theme.palette.orange,
  },
  carouselBigTitle: {
    fontWeight: 600,
    color: theme.palette.white,
    marginTop: "10px",
    marginBottom: "10px",
  },
  carouselContainer: {
    position: "relative",
    textAlign: "start",
    color: theme.palette.white,
  }
}));

const HomeCarousel = () => {
  const classes = useStyles();
  
  return (
    <Carousel 
      autoPlay
      interval={6000}
      showIndicators={false}
      howStatus={false}
      showThumbs={false}
      stopOnHover
      swipeable
      infiniteLoop
      emulateTouch
    >
      <div className={classes.carouselContainer}>
        <img className={classes.carousel} alt="carousel-1" src="images/carousel-img-1.jpg"/>
        <div className={classes.textOverCarousel}>
          <Typography className={classes.carouselSmallTitle} variant="subtitle2">PROMO JANVIER</Typography>
          <Typography className={classes.carouselBigTitle} variant="h1">-10% Sur Nos Salles de Bains</Typography>
          <Button color="primary" variant="contained">J'en profite</Button>
        </div>
      </div>
      <div className={classes.carouselContainer}>
        <img className={classes.carousel} alt="carousel-1" src="images/carousel-img-1.jpg"/>
        <div className={classes.textOverCarousel}>
          <Typography className={classes.carouselSmallTitle} variant="subtitle2">PROMO JANVIER</Typography>
          <Typography className={classes.carouselBigTitle} variant="h1">-10% Sur Nos Salles de Bains</Typography>
          <Button color="primary" variant="contained">J'en profite</Button>
        </div>
      </div>
    </Carousel>
  );
};

export default HomeCarousel;