import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { MoreHoriz } from '@material-ui/icons';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography
} from '@material-ui/core';
import mainTypes from 'common/mainTypes';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  ...theme.common,
  title: {
    padding: '10px 15px',
    fontWeight: 600
  }
}));

const PopoverMenu = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Button
        id={'wazza'}
        variant="contained"
        color="primary"
        className={classes.mainButton}
        endIcon={<MoreHoriz />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Nos Produits
      </Button>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        disableRestoreFocus
        onClose={() => setAnchorEl(null)}
      >
        <Typography className={classes.title} variant="h5">
          Catégorie de produits
        </Typography>
        <Divider />
        <List>
          {mainTypes.map((mainType) => (
            <NavLink
              key={mainType.ID}
              to={'/products-category-select/' + mainType.ID}
              onClick={() => setAnchorEl(null)}
            >
              <ListItem button>
                <ListItemIcon>{mainType.icon}</ListItemIcon>
                <ListItemText primary={mainType.Name} />
              </ListItem>
            </NavLink>
          ))}
        </List>
      </Popover>
    </React.Fragment>
  );
};

export default PopoverMenu;
