import React from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import ProductRow from "./components";
import { Grid } from "@mui/material";
import {
  getProductList,
  getProductsbyMainType,
  getProductsByType,
} from "cfiAPI";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const useStyles = makeStyles((theme) => ({
  mainButton: {
    ...theme.common.mainButton,
    color: theme.palette.white,
  },
  tableBody: {
    marginBottom: "35px",
  },
  priceLabel: {
    color: "#95d022",
    fontWeight: 600,
  },
  gridImageCell: {
    padding: "35px",
  },
  gridInfoCell: {
    padding: "35px",
  },
  gridActionCell: {
    padding: "35px",
  },
}));

/**
 * Gets and display the products of the specified category
 * @param {String} id Id of the product subType
 * @param {Array} breadcrumbs
 * @param {String} mainTypeId when selecttion skipped, is used to get products from mainType
 * @param {String} productTypeId when selecttion skipped, is used to get products from productType
 * @returns
 */
const ProductList = ({ id, breadcrumbs, mainTypeId, productTypeId }) => {
  const classes = useStyles();

  const [productList, setProductList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let response = null;

      console.log("ProductList", id, mainTypeId, productTypeId);
      if (id) {
        console.log("ID");
        response = await getProductList(id);
      } else {
        if (productTypeId) {
          console.log("productTypeId");
          response = await getProductsByType(productTypeId);
        } else if (mainTypeId) {
          console.log("mainTypeId");
          response = await getProductsbyMainType(mainTypeId);
        }
      }

      setProductList(response.data);
      setLoading(false);
    };

    getData();
  }, [id]);

  return (
    <Container>
      <Breadcrumbs
        sx={{ mb: 2 }}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <TableContainer className={classes.tableBody} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            <ProductRow loading={loading} productList={productList} />
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && productList.length === 0 && (
        <Grid sx={{ pt: 3, pb: 6 }}>Aucun produit à afficher</Grid>
      )}
    </Container>
  );
};

export default ProductList;
