import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Container,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import TopMessage from '../TopMessage';
import { Logo } from 'components';
import { NavLink, useHistory } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { CartContext } from 'contexts/CartContext';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import links from 'common/links';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    boxShadow: 'none'
  },
  title: {
    fontWeight: 700
  },
  text: {
    fontWeight: 600
    // color: theme.palette.black
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  transparentBg: {
    backgroundColor: 'transparent !important'
  }
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  let history = useHistory();
  const { isLoggedIn, logout } = React.useContext(AuthContext);
  const { getCartCount, getCartPrice } = React.useContext(CartContext);

  const [notifications] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const accountClick = () => {
    if (isLoggedIn) {
      logout();
    } else {
      history.push('/login');
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onSidebarOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      {...rest}
      position="static"
      className={clsx(classes.root, className)}
    >
      <TopMessage />
      <Container>
        <Toolbar>
          <NavLink to="/">
            <Logo />
          </NavLink>
          <div className={classes.flexGrow} />
          <Hidden mdDown>
            <IconButton
              className={classes.transparentBg}
              disableRipple
              disableFocusRipple
              color="inherit"
              onClick={accountClick}
            >
              <Badge
                badgeContent={notifications.length}
                color="primary"
                variant="dot"
              >
                <PersonIcon />
              </Badge>
              &nbsp;
              <Typography className={classes.text}>
                {isLoggedIn ? 'Mon Compte' : 'Connexion'}
              </Typography>
            </IconButton>
            {isLoggedIn && (
              <IconButton
                className={classes.transparentBg}
                disableRipple
                disableFocusRipple
                color="inherit"
                onClick={(_) => history.push('/cart')}
              >
                <Badge
                  badgeContent={getCartCount()}
                  color="primary"
                  variant="standard"
                >
                  <LocalMallIcon />
                </Badge>
                &nbsp;
                <Typography className={classes.text}>
                  Panier: {getCartPrice()} €
                </Typography>
              </IconButton>
            )}
          </Hidden>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={handleClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              <MenuItem onClick={handleClose}>
                <IconButton
                  className={classes.transparentBg}
                  disableRipple
                  disableFocusRipple
                  color="inherit"
                  onClick={accountClick}
                >
                  <Badge
                    badgeContent={notifications.length}
                    color="primary"
                    variant="dot"
                  >
                    <PersonIcon />
                  </Badge>
                  &nbsp;
                  <Typography className={classes.text}>
                    {isLoggedIn ? 'Mon Compte' : 'Connexion'}
                  </Typography>
                </IconButton>
              </MenuItem>
              {isLoggedIn && (
                <MenuItem onClick={handleClose}>
                  <IconButton
                    className={classes.transparentBg}
                    disableRipple
                    disableFocusRipple
                    color="inherit"
                    onClick={(_) => history.push('/cart')}
                  >
                    <Badge
                      badgeContent={getCartCount()}
                      color="primary"
                      variant="standard"
                    >
                      <LocalMallIcon />
                    </Badge>
                    &nbsp;
                    <Typography className={classes.text}>
                      Panier: {getCartPrice()} €
                    </Typography>
                  </IconButton>
                </MenuItem>
              )}
              <hr />
              {links.map((link) => (
                <MenuItem key={link.name} onClick={handleClose}>
                  <NavLink className={classes.link} to={link.href}>
                    {link.name}
                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
