import {
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  p25: theme.paddings.p25,
  icon: {
    maxHeight: "35px",
  },
  fullHeight: {
    height: "100%",
  },
  cardTitle: {
    fontWeight: 600,
  },
}));

const cardInfo = [
  {
    id: 1,
    icon: "icons/shopping-bag.svg",
    title: "Un large choix de produits",
    body: "Dans notre catalogue de produits, vous trouverez plus de 14.500 articles que nous avons en stock dans nos points de vente et entrepôts.",
  },
  {
    id: 2,
    icon: "icons/price-tag.svg",
    title: "Des prix intéressants",
    body: "Nous sommes constamment en négociation avec nos fournisseurs pour vous proposer des produits avec le meilleur rapport qualité/prix",
  },
  {
    id: 3,
    icon: "icons/credit-card.svg",
    title: "Paiements sécurisés",
    body: "Your information is transmitted in a safe",
  },
  {
    id: 4,
    icon: "icons/callback.svg",
    title: "Un service client au top !",
    body: "la satisfaction client fait partie de l’ADN de C-Fi. Chez nous, le client est roi !",
  },
  {
    id: 5,
    icon: "icons/fast-delivery.svg",
    title: "Livraison rapide",
    body: "We offers free shipping on all domestic orders",
  },
];

const HomeCardSection = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      {cardInfo.map((info) => (
        <Grid item key={info.id} className={classes.p25} xs={12} sm={6} md={3}>
          <Paper elevation={0} className={classes.fullHeight}>
            <ListItem>
              <ListItemIcon>
                <img className={classes.icon} alt="icon" src={info.icon} />
              </ListItemIcon>
              <ListItemText>
                <Typography className={classes.cardTitle} variant="subtitle1">
                  {info.title}
                </Typography>
                <Typography variant="subtitle2">{info.body}</Typography>
              </ListItemText>
            </ListItem>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default HomeCardSection;
