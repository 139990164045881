import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  InputLabel,
  TextField,
  FormControlLabel,
  Switch
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  label: {
    marginTop: theme.spacing(1),
    fontWeight: 600,
    fontSize: '17px'
  },
  textField: {
    paddingTop: theme.spacing(1)
  },
  errorTextField: {
    border: 'dashed 1px red'
  },
  ...theme.common
}));

const CfiTextField = ({
  label,
  name,
  value,
  type = 'text',
  handleChange,
  touched,
  errors
}) => {
  const classes = useStyles();

  if (type === 'bool' || type === 'boolean') {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={handleChange}
            name={name}
            color="primary"
          />
        }
        label={label}
      />
    );
  }

  return (
    <React.Fragment>
      <InputLabel className={classes.label} shrink htmlFor={name}>
        {label}
      </InputLabel>
      <TextField
        id={name}
        name={name}
        type={type}
        variant="filled"
        className={classes.textField}
        inputProps={{
          className: clsx({
            [classes.textField]: true,
            [classes.errorTextField]: Boolean(touched && errors)
          })
        }}
        fullWidth
        InputProps={{ disableUnderline: true }}
        value={value}
        onChange={handleChange}
        error={touched && Boolean(errors)}
        helperText={touched && errors}
      />
    </React.Fragment>
  );
};

export default CfiTextField;
