import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Section } from "components";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { CartItem } from "./components";
import { useHistory } from "react-router-dom";
import { CartContext } from "contexts/CartContext";
import { TextField } from "@mui/material";
import { SnackbarContext } from "contexts/SnackbarContext";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "75px",
  },
  title: {
    fontWeight: 700,
  },
  grid: {
    marginTop: "35px",
  },
  emptyTitle: {
    marginTop: "100px",
    marginBottom: "100px",
  },
  summaryCard: {
    backgroundColor: theme.palette.background.default,
  },
  summaryCardDivider: {
    backgroundColor: "#dbdbe0",
    marginTop: "10px",
    marginBottom: "10px",
    opacity: 0.6,
  },
  ...theme.common,
  ...theme.margins,
  ...theme.paddings,
}));

const Cart = () => {
  const classes = useStyles();
  let history = useHistory();

  const {
    products,
    deleteItem,
    getCartHTVA,
    getCartVAT,
    getCartDiscount,
    getCartTotal,
    confirmSnotaBasket,
  } = React.useContext(CartContext);

  const { errorMessage, successMessage } = React.useContext(SnackbarContext);

  const [reference, setReference] = useState("");

  const cartDiscount = getCartDiscount();

  const checkoutClicked = async () => {
    var resp = await confirmSnotaBasket(reference);
    if (resp) {
      successMessage("Commande confirmé");
    } else {
      errorMessage("Une erreur s'est produite. Reessayez plus tard");
    }
  };

  return (
    <Section light className={classes.root}>
      <Typography
        hidden={products.length === 0}
        className={classes.title}
        variant="h1"
      >
        Shopping Cart
      </Typography>
      {products.length === 0 && (
        <Grid className={classes.emptyTitle} container justify="center">
          <Grid item xs={6} className={classes.textAlignCenter}>
            <Typography
              className={clsx(classes.mb35, classes.title)}
              variant="h2"
            >
              Votre panier est actuellement vide
            </Typography>
            <Button
              onClick={(_) => history.push("/")}
              className={classes.mainButton}
            >
              Continue shopping
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        className={classes.grid}
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
        hidden={products.length === 0}
      >
        <Grid item xs={12} md={9}>
          <Grid container direction="column" spacing={2}>
            {products.map((cartItem) => (
              <CartItem
                key={cartItem.DetailID}
                productInfo={cartItem}
                deleteItem={deleteItem}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card className={classes.summaryCard}>
            <CardContent>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                spacing={2}
                hidden={products.length === 0}
              >
                <Grid className={clsx(classes.pt0, classes.fullWidth)} item>
                  <Divider
                    className={clsx(
                      classes.invisible,
                      classes.mt0,
                      classes.summaryCardDivider
                    )}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.bold)}>
                    CART TOTAL
                  </Typography>
                </Grid>
                <Grid className={clsx(classes.fullWidth)} item>
                  <Divider
                    className={classes.summaryCardDivider}
                    light
                    fullWidth
                  />
                </Grid>
                <Grid container direction="row" justify="space-between">
                  <Grid item>
                    <Typography>Sous-total</Typography>
                  </Grid>
                  <Grid item>
                    <Typography align="right">{getCartHTVA()} €</Typography>
                  </Grid>
                </Grid>
                <Grid className={clsx(classes.fullWidth)} item>
                  <Divider
                    className={classes.summaryCardDivider}
                    light
                    fullWidth
                  />
                </Grid>
                <Grid container direction="row" justify="space-between">
                  <Grid item>
                    <Typography>TVA</Typography>
                  </Grid>
                  <Grid item>
                    <Typography align="right">
                      {getCartVAT().toFixed(2)} €
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className={clsx(classes.fullWidth)} item>
                  <Divider
                    className={classes.summaryCardDivider}
                    light
                    fullWidth
                  />
                </Grid>
                {/*
                  <Grid container direction="row" justify="space-between">
                    <Grid item>
                      <Typography>Ristourne</Typography>
                    </Grid>
                    <Grid item>
                      <Typography align="right">
                        - {cartDiscount.toFixed(2)} €
                      </Typography>
                      <Typography align="right" variant="caption">
                        Octroyé par utilisateur
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid className={clsx(classes.fullWidth)} item>
                    <Divider
                      className={classes.summaryCardDivider}
                      light
                      fullWidth
                    />
                  </Grid>
                */}
                <Grid container direction="row" justify="space-between">
                  <Grid item>
                    <Typography>Total</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.bold}>
                      {getCartTotal()} €
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className={clsx(classes.fullWidth)} item>
                  <Divider
                    className={classes.summaryCardDivider}
                    light
                    fullWidth
                  />
                </Grid>
                <Grid className={clsx(classes.fullWidth)} item>
                  <Typography>Référence de commande</Typography>
                  <TextField
                    fullWidth
                    onChange={(e) => setReference(e.target.value)}
                  />
                </Grid>
                <Grid className={clsx(classes.fullWidth)} item>
                  <Divider
                    className={classes.summaryCardDivider}
                    light
                    fullWidth
                  />
                </Grid>
                <Grid className={clsx(classes.fullWidth)} item>
                  <Button
                    fullWidth
                    className={classes.mainButton}
                    onClick={checkoutClicked}
                  >
                    CHECKOUT
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Section>
  );
};

export default Cart;
