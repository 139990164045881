import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import {
  getCategoryLevel2,
  getCategoryLevel3,
  getCategoryLevel4,
} from "cfiAPI";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { makeStyles } from "@material-ui/styles";
import { useHistory, useParams, NavLink } from "react-router-dom";
import mainTypes from "common/mainTypes";
import ProductTypeGridElement from "./components";
import Skeleton from "@mui/material/Skeleton";
import { Section } from "components";
import ProductList from "views/ProductList";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingBottom: "55px",
  },
  title: {
    fontWeight: 700,
  },
  grid: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
  gridItem: {
    cursor: "pointer",
  },
  productTypeImage: {},
}));

const Products = () => {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [categories, setCategories] = React.useState([]);

  // used for when the user clicks 'see all products'
  const [shouldByPassCatSel, setShouldByPassCatSel] = React.useState(false);

  let { id, mainSubTypeId, productTypeId, productSubType } = useParams();
  let history = useHistory();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);

      let categoryId = id;
      let categoryGetter = getCategoryLevel2;
      if (productTypeId) {
        categoryId = productTypeId;
        categoryGetter = getCategoryLevel4;
      } else if (mainSubTypeId) {
        categoryId = mainSubTypeId;
        categoryGetter = getCategoryLevel3;
      }
      var response = await categoryGetter(categoryId);
      setCategories(response.data);
      setLoading(false);
    };

    setCategories([]);
    getData();
  }, [id, mainSubTypeId, productTypeId]);

  const selectProductSubType = (selectedId) => {
    if (id && mainSubTypeId && productTypeId) {
      history.push(
        `/products-category-select/${id}/${mainSubTypeId}/${productTypeId}/${selectedId}`
      );
    } else if (id && mainSubTypeId) {
      history.push(
        `/products-category-select/${id}/${mainSubTypeId}/${selectedId}`
      );
    } else if (id) {
      history.push(`/products-category-select/${id}/${selectedId}`);
    }
  };

  const tableType = mainTypes.filter((x) => x.ID === id)[0];

  const breadcrumbs = [<Typography key="4">{tableType.Name}</Typography>];

  // If Product sub type (level 4) selected
  if (productSubType) {
    breadcrumbs.unshift(
      <NavLink
        underline="hover"
        key="3"
        onClick={() => setShouldByPassCatSel(false)}
        to={`/products-category-select/${id}/${mainSubTypeId}/${productTypeId}`}
      >
        {productTypeId}
      </NavLink>
    );
  }

  // If Product type (level 3) selected
  if (productTypeId) {
    breadcrumbs.unshift(
      <NavLink
        underline="hover"
        key="2"
        onClick={() => setShouldByPassCatSel(false)}
        to={`/products-category-select/${id}/${mainSubTypeId}`}
      >
        {mainSubTypeId}
      </NavLink>
    );
  }

  // If Main sub type (level 2) selected
  if (mainSubTypeId) {
    breadcrumbs.unshift(
      <NavLink
        underline="hover"
        key="1"
        onClick={() => setShouldByPassCatSel(false)}
        to={`/products-category-select/${id}`}
      >
        {id}
      </NavLink>
    );
  }

  // If Product sub type (level 4) selected
  if (shouldByPassCatSel || productSubType) {
    return (
      <ProductList
        breadcrumbs={breadcrumbs}
        id={productSubType}
        mainTypeId={id}
        productTypeId={productTypeId}
      />
    );
  }

  return (
    <Section light>
      <Container className={classes.section}>
        <Stack direction="row" justifyContent="space-between">
          <Typography className={classes.title} variant="h1">
            {tableType.Name}
          </Typography>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => setShouldByPassCatSel(true)}
          >
            Montrer tous les produits
          </Button>
        </Stack>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Grid
          className={classes.grid}
          container
          spacing={10}
          direction="row"
          justify="center"
          alignItems="center"
        >
          {loading ? (
            <>
              <Grid xs={3} sm={6} item className={classes.gridItem}>
                <Skeleton variant="rect" height={125} />
                <Skeleton variant="text" />
              </Grid>
              <Grid xs={3} sm={6} item className={classes.gridItem}>
                <Skeleton variant="rect" height={125} />
                <Skeleton variant="text" />
              </Grid>
            </>
          ) : (
            <ProductTypeGridElement
              types={categories}
              onClick={selectProductSubType}
            />
          )}
        </Grid>
      </Container>
    </Section>
  );
};

export default Products;
